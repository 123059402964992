<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <!-- Products Table -->
    <a-row :gutter="24" type="flex">
      <!-- Products Table Column -->
      <a-col :span="24" class="mb-24">
        <!-- Products Table Card -->
        <CardProductsTable
          :data="table1Data"
          :columns="table1Columns"
          
        ></CardProductsTable>
        <!-- / Products Table Card -->


      </a-col>
      <!-- / Products Table Column -->
    </a-row>
    <!-- / Products Table -->
  </div>
</template>

<script>
// "Products" table component.
import CardProductsTable from "../components/Cards/CardProductsTable";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { Modal } from "ant-design-vue";

import request from "../utils/request";
import { list } from "@/api/product";
// "Products" table list of columns and their properties.
const table1Columns = [
  {
    title: "",
    dataIndex: "productKey",
    scopedSlots: { customRender: "selectorColumn" },
    width: 50,
  },
  {
    title: "",
    dataIndex: "image",
    scopedSlots: { customRender: "productimg"},
    width: 100,
  },
  {
    title: "Product name",
    dataIndex: "name",
  },
  {
    title: "Competitors",
    dataIndex: "urls",
    scopedSlots: { customRender: "competitorCol" },
  },
  {
    title: "Categories",
    dataIndex: "category",
    scopedSlots: { customRender: "categoryCol" },
  },
  {
    title: "Unique ID",
    dataIndex: "uniqueid",
  },
  {
    title: "Edit",
    dataIndex: "productKey",
    key: "editid",
    scopedSlots: { customRender: "editBtn" },
    width: 40,
  },
  {
    title: "Delete",
    dataIndex: "productKey",
    key: "n",
    scopedSlots: { customRender: "delBtn" },
    width: 40,
  },
  {
    title: "More details",
    dataIndex: "productKey",
    key: "n1",
    scopedSlots: { customRender: "moreBtn" },
    width: 40,
  },
];

// "Authors" table list of rows and their properties.
const table1Data = [];

export default {
  components: {
    CardProductsTable,
  },
  methods: {
    loadData() {
      list().then((response) => {
        this.table1Data = response;
      });
    },
  },
  data() {
    return {
      // Associating "Products" table data with its corresponding property.
      table1Data: table1Data,

      // Associating "Products" table columns with its corresponding property.
      table1Columns: table1Columns,
      headers: {},
      //function: product-09
      action:
        (process.env.VUE_APP_API_BASE_URL
          ? process.env.VUE_APP_API_BASE_URL
          : "") + "/api/bulkproduct",
    };
  },
  mounted() {
    const token = storage.get(ACCESS_TOKEN);
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
      this.headers["Authorization"] = "Bearer " + token;
    }
    this.loadData();
  },
};
</script>

<style lang="scss">
</style>
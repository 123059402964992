<template>

	<!-- Authors Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h5 class="font-semibold m-0">{{data.length}} Products</h5>
				</a-col>
				<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<!-- <a-input-search v-model='q' @search="onSearch">
					</a-input-search>
					<a-radio-group size="small">
						<a-radio-button value="all">ALL</a-radio-button>
						<a-radio-button value="online">ACTIVE</a-radio-button>
					</a-radio-group> -->
					<a-button type="primary" icon="plus" shape="round" @click="addProduct">
					Add product
					</a-button>

					<a-upload
					name="file"
					:multiple="false"
					:action="action"
					@change="handleupload"
					:headers="headers"
					:showUploadList="false"
					>
					<a-button
						shape="round"
						style="margin-left: 10px"
						icon="upload"
						type="primary"
						>Upload</a-button
					>
					</a-upload>
				</a-col>
			</a-row>
		</template>
		 <a-checkbox-group style="display:inline" @change="Selected">
		<a-table :columns="columns" :data-source="data" :pagination="true" v-show="data.length">
			<template slot="selectorColumn" slot-scope="row">
				<a-checkbox :value="row"></a-checkbox>
			</template>
			
			<template slot="productimg" slot-scope="row">
				<a-avatar shape="square" :src="row" />
			</template>

			<template slot="editBtn" slot-scope="row">
				<a-button shape="circle" icon="edit" :data-id="row.key" class="btn-edit" @click="editproduct(row)">
				</a-button>
			</template>

			<template slot="delBtn" slot-scope="row">
				<a-button shape="circle" icon="delete" :data-id="row.key" class="btn-edit" @click="delproduct(row)">
				</a-button>
			</template>

			<template slot="moreBtn" slot-scope="row">
				<a-button shape="circle" icon="right" :data-id="row.key" class="btn-edit" @click="productdetail(row)">
				</a-button>
			</template>

			<template slot="categoryCol" slot-scope="categories">
				<template v-for="itm in categories">
				<a-tag :key="itm.categoryid" style="margin-top:10px"><router-link :to="{path:'categorydetail',query:{categoryid:itm.categoryid}}">{{ itm.name }}</router-link></a-tag>
				</template>
			</template>

			<template slot="competitorCol" slot-scope="row">
				{{(row?row.length:0)}}
			</template>
			
		</a-table>
		</a-checkbox-group>
		 <a-button shape="round" @click="handleSelect" type="primary" style="margin-left:10px">Delete selected</a-button>
	
	</a-card>
	<!-- / Authors Table Card -->

</template>

<script>
	import {search} from "@/api/product";
	import {deleteproduct} from '@/api/product';
	import { message } from 'ant-design-vue';
	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				q:'',
				selectedproducts:[],
				headers:{},
				action:
					(process.env.VUE_APP_API_BASE_URL
					? process.env.VUE_APP_API_BASE_URL
					: "") + "/api/bulkproduct",
			}
		},
		methods:{
    //function: product-02
    addProduct() {
      this.$router.push({ path: "/productedit", query: { productid: 0 } });
    },
    //error handling for function product-09
    handleupload(info) {
      if (info.file.status === "error") {
        notification.error({
          message: `${info.file.name} file upload failed.`,
        });
      }
      if (info.file.status === "done" && info.file.response[1] === 0) {
        notification.success({
          message: `${info.file.name} file uploaded successfully, ${info.file.response[0]} products have been uploaded.`,
          style: {
            width: "600px",
            marginLeft: `${335 - 600}px`,
          },
        });
        this.loadData();
      }
      if (info.file.status === "done" && info.file.response[1] > 0) {
        Modal.warning({
          title: "There are duplicates in your file!",
          content: `${info.file.response[0]} products have been uploaded, ${info.file.response[1]} products are duplicates. Duplicate IDs: ${info.file.response[2]} `,
          okText: "OK",
        });
      }
    },

			//function: product-03
			editproduct(key){
				this.$router.push({path:'/productedit',query:{productid:key}})
			},
			//function: prodct-04
			delproduct(key){
				deleteproduct(key).then(data=>{
					for (var i=0; i < this.data.length; i++)
						if (this.data[i].productKey==key)
							this.data.splice(i,1);
					message.success('This product has been deleted successfully!');
				});
			},
			//function: product-05
			productdetail(key){
				this.$router.push({path:'/productdetail', query:{productid:key}});
			},
			onSearch(v){
				search({q:v}).then(response=>{
					this.data=response;
				});
			},
			
			Selected(values){
            this.selectedproducts = values;
            },
			//function: product-06
			handleSelect(){
			this.selectedproducts .forEach(item => this.delproduct(item))
			}
		}
	})

</script>